body {
  overflow-x: hidden;
  background-color: white;
}

.main-container {
  margin-left: 150px;
}

label {
  @apply text-gray-600;
  @apply text-sm;
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    height: 4px;
    padding-top: 1rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 100vh;
  }
}

.carousel-html-content .carousel-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.un {
  display: inline-block;
  padding-bottom: 1px;
  background-image: linear-gradient(#a41748, #a41748);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.un:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
}

.frame_video {
  display: flex;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  background: rgb(99, 29, 51);
  background: -moz-linear-gradient(180deg, rgba(99, 29, 51, 1) 20%, rgba(182, 36, 82, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(99, 29, 51, 1) 20%, rgba(182, 36, 82, 1) 100%);
  background: linear-gradient(180deg, rgba(99, 29, 51, 1) 20%, rgba(182, 36, 82, 1) 100%);
  background-image: url("/assets/themes/casadege/images/fundo-video-casa-de-ge.webp");
  background-repeat: no-repeat;
  background-size: 100%;
}

.frame_video iframe {
  display: block;
  margin: auto;
  box-shadow: 5px 20px 80px rgb(0 0 0 / 40%);
}
